import {Query} from '@directus/types'
import {FaqCategoryTypeDTO, FaqTypeDTO, FaqTypeEntity} from './faq.type'
import {consola} from 'consola'
import {useFaqUtils} from './faq.utils'
import arrayToTree from 'array-to-tree'

export const useFaq = () => {
  const {getItems, getItemById} = useDirectusItems()
  const {generateFaqDto, generateFaqCategoryDto, generateLangSwitch, logger} = useFaqUtils(inject('globalSettings'))
  const getFaqIndex = async (languageCode: string) => {
    return getFaqCategoriesEntity({
      query: {
        filter: {
          show: {
            _eq: true
          },
          status: {
            _eq: 'published'
          }
        },
        fields: [
          'id', 'status',
          'date_created',
          'show', 'parent',
          'icon', 'sort',
          'childs', 'translations.id',
          'translations.languages_code',
          'translations.title',
          'translations.slug'],
        sort: ['-date_created'],
        limit: 6
      }
    })
      .then((categories) => ({
        model: Array.isArray(categories)
          ? categories.map((item: any) => generateFaqCategoryDto(item, languageCode))
          : [],
        lang_switch: generateLangSwitch('faq', languageCode)
      }))
      .catch((e) => {
        logger.error('ERR getFaqIndex : ', e)
        return []
      })

  }

  const getFaqsByCategoryId = async (
    category_id: string | number,
    languageCode: string,
    options?: {
      page?: number
      limit?: number
    }
  ) => {
    return Promise.all([
      getFaqsEntity({
        query: {
          filter: {
            _and: [
              {
                status: {
                  _eq: 'published'
                }
              },
              {
                category: {
                  id: {
                    _eq: category_id
                  }
                }
              }
            ]
          },
          fields: ['*', 'translations.*'],
          sort: ['-date_created'],
          limit: options?.limit || 10,
          page: options?.page || 1
        }
      }),
      getFaqCategoriesEntity({
        id: category_id,
        query: {
          fields: [
            'id',
            'status',
            'show',
            'parent',
            // 'icon.id',
            // 'icon.title',
            // 'icon.description',
            'icon',
            'sort',
            'childs',
            'translations.*',
            'translations.seo_detail.*',
            'faqs.id',
            'faqs.status',
            'faqs.translations.languages_code',
            'faqs.translations.title',
            'faqs.translations.slug'
          ],
          limit: options?.limit || 10
        }
      })
    ])
      .then((data: any) => {
        if (!data || data.length !== 2) throw Error('Promise get data getFaqsByCategoryId')

        let faqs = data[0]
        let category = data[1]

        if (faqs && Array.isArray(faqs)) {
          return {
            faqs: faqs.map((item: any) => generateFaqDto(item, languageCode)),
            category: generateFaqCategoryDto(category as any, languageCode),
            lang_switch: category['lang_switch'] || []
          }
        }
        throw Error('Error: get faqs')
      })
      .catch((e) => {
        logger.error('ERR getFaqsByCategoryId : ', e)
        return {}
      })

  }

  const getFaqById = async (faq_id: string | number, languageCode: string, seo_fields: string[] = []) => {
    return Promise.all([
      getFaqsEntity({
        id: faq_id,
        query: {
          filter: {
            status: {
              _eq: 'published'
            }
          },
          fields: ['id', 'date_created', 'status', 'category', 'translations.id', 'translations.languages_code', 'translations.title', 'translations.content', 'translations.slug', 'translations.seo_detail', ...seo_fields]
        }
      }),
      getFaqCategoriesEntity({
        query: {
          fields: [
            'id',
            'status',
            'show',
            'parent',
            'date_created',
            'icon',
            'sort',
            'childs',
            'translations.*',
            'translations.seo_detail.*',
            'faqs.id',
            'faqs.status',
            'faqs.translations.languages_code',
            'faqs.translations.title',
            'faqs.translations.slug',
          ],
          sort: ['-show', 'date_created'],
          limit: -1
        }
      })
    ])
      .then((data: any) => {
        if (!data || data.length !== 2) throw Error('Promise get data getFaqById')

        let faq = data[0]
        let categories = data[1] as Array<any>

        return {
          model: Object.keys(faq).length > 0 ? generateFaqDto(faq as FaqTypeEntity, languageCode) : {},
          category: arrayToTree(
            categories.map((category: any) => generateFaqCategoryDto(category, languageCode)),
            {
              parentProperty: 'parent',
              childrenProperty: 'childs'
            }
          ),
          lang_switch:
            Object.keys(faq).length > 0 ? generateFaqDto(faq as any, languageCode)['lang_switch'] : []
        }
      })
      .catch((e: any) => {
        logger.error('ERR  getFaqById: ', e)
        return {}
      })

  }

  const getFaqsByQuery = async (languageCode: string, query?: Query | any) => {

    return getFaqsEntity({
      query: {
        page: 1,
        limit: query?.limit,
        sort: ['-date_created'],
        fields: [
          'id',
          'status',
          'date_created',
          'category',
          'translations.id',
          'translations.languages_code',
          'translations.title',
          'translations.content',
          'translations.slug'],
        filter: {
          _and: [
            {
              status: {
                _eq: 'published'
              }
            },
            {
              show: {
                _eq: true
              },
            }
          ]
        },
        ...(query ?? {}),
        meta: 'filter_count'
      }
    })
      .then((faqs: any) => ({
        faqs: faqs.data.map((item: any) => generateFaqDto(item, languageCode)),
        filter_count: faqs.meta?.filter_count || 0
      }))
      .catch((e: any) => {
        logger.error('Error getFaqsByQuery', e)
        return []
      })

  }

  //===============================================
  const getFaqCategoriesEntity = async (options?: {
    id?: string | number
    query?: Query
  }): Promise<Array<FaqCategoryTypeDTO> | FaqCategoryTypeDTO> => {
    let data: any = {
      collection: 'faq_categories',
      params: {
        fields: ['*'],
        filter: {
          _and: [
            {
              status: {
                _eq: 'published'
              }
            },
            {
              show: {
                _eq: true
              },
            }
          ]
        }
      }
    }
    if (options?.query) {
      data.params = {
        ...data.params,
        ...options.query
      }
    }

    if (options?.id) {
      data['id'] = options.id.toString()
      return getItemById(data)
    }

    return getItems(data)
  }

  const getFaqsEntity = async (options?: {
    id?: string | number
    query?: Query
  }): Promise<Array<FaqTypeDTO> | FaqTypeDTO> => {
    let data: any = {
      collection: 'faqs',
      params: {
        fields: ['*']
      }
    }

    if (options?.query) {
      data.params = {
        ...data.params,
        ...options.query
      }
    }

    if (options?.id) {
      data['id'] = options.id.toString()

      return getItemById(data)
    }

    return getItems(data)
  }

  return {
    getFaqCategoriesEntity,
    getFaqsEntity,
    getFaqIndex,
    getFaqsByCategoryId,
    getFaqById,
    getFaqsByQuery,
    logger
  }
}
